<template>
  <div class="about">
	<!-- Page Title
	============================================= -->
	<section id="page-title" class="page-title-parallax include-header" style="padding: 250px 0; background-image: url('/assets/images/about/hero.jpg'); background-size: cover; background-position: center center;" data-bottom-top="background-position:0px 400px;" data-top-bottom="background-position:0px -500px;">

		<div class="container clearfix">
			<h1>Who We Are</h1>
		</div>

	</section><!-- #page-title end -->

	<!-- Content
	============================================= -->
	<section id="content">
		<div class="content-wrap">
			<div class="container clearfix">

				<div class="row col-mb-50 mb-0">

					<div class="col-lg-4">

						<div class="heading-block fancy-title border-bottom-0 title-bottom-border">
							<h4>Why choose <span>Us</span>.</h4>
						</div>
						<p>Your success is our success. We have built countless powerful business partnerships with a solid <strong>foundation of trust</strong>. Our empowered customers have established high-performing offshore teams that have saved them thousands of work hours, affording them the ability to focus on growth.</p>
					</div>

					<div class="col-lg-4">

						<div class="heading-block fancy-title border-bottom-0 title-bottom-border">
							<h4>Our <span>Mission</span>.</h4>
						</div>
						<p>We are <strong>empowering our customers</strong> to focus on their core business. Time is a valuable resource. We can execute a variety of tasks to free up your time so that you can focus your energy on growing your business.</p>
					</div>

					<div class="col-lg-4">

						<div class="heading-block fancy-title border-bottom-0 title-bottom-border">
							<h4>What we <span>Do</span>.</h4>
						</div>
						<p>We drive performance. We are high-performing individuals that use data to construct a <strong>performance-driven culture</strong>. We put people first. High investments in people and culture will produce a high-performing team, delivering high-quality services to our customers.</p>
					</div>	

				</div>

			</div>

			<div class="section m-0">
				<div class="container clearfix">

					<div class="row col-mb-50">

						<div class="col-sm-6 col-lg-3 text-center">
							<i class="i-plain i-xlarge mx-auto icon-line2-clock"></i>
							<div class="counter counter-lined">24</div>
							<h5>Hours of Coverage Per Day</h5>
						</div>

						<div class="col-sm-6 col-lg-3 text-center">
							<i class="i-plain i-xlarge mx-auto mb-0 icon-hourglass-start"></i>
							<div class="counter counter-lined">365</div>
							<h5>Days of Coverage Per Year</h5>
						</div>

						<div class="col-sm-6 col-lg-3 text-center">
							<i class="i-plain i-xlarge mx-auto mb-0 icon-calendar-1"></i>
							<div class="counter counter-lined">500,000</div>
							<h5>Annual work hours saved for customers per year</h5>
						</div>

						<div class="col-sm-6 col-lg-3 text-center">
							<i class="i-plain i-xlarge mx-auto mb-0 icon-dollar"></i>
							<div class="counter counter-lined">80%</div>
							<h5>Possible operating cost savings</h5>
						</div>

					</div>

				</div>
			</div>

			<div class="container clearfix">

				<div class="clear"></div>

				<div class="heading-block center">
					<h4>Our Clients</h4>
				</div>

				<ul class="clients-grid grid-2 grid-sm-4 grid-md-3 mb-0">
					<li class="grid-item"><img src="/assets/images/clients/aqqire.png" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/manscaped.jpeg" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/cvent.jpeg" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/iscribe.jpeg" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/copper.png" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/brightwheel.png" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/upcity.png" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/uservoice.png" alt="Clients"></li>
					<li class="grid-item"><img src="/assets/images/clients/affinity.jpg" alt="Clients"></li>
				</ul>

			</div>
			<!-- what clients say -->
			<!-- <div class="section footer-stick">

				<h4 class="text-uppercase center">What <span>Clients</span> Say?</h4>

				<div class="fslider testimonial testimonial-full" data-animation="fade" data-arrows="false">
					<div class="flexslider">
						<div class="slider-wrap">
							<div class="slide">
								<div class="testi-image">
									<a href="#"><img src="/assets/images/testimonials/3.jpg" alt="Customer Testimonails"></a>
								</div>
								<div class="testi-content">
									<p>Similique fugit repellendus expedita excepturi iure perferendis provident quia eaque. Repellendus, vero numquam?</p>
									<div class="testi-meta">
										Steve Jobs
										<span>Apple Inc.</span>
									</div>
								</div>
							</div>
							<div class="slide">
								<div class="testi-image">
									<a href="#"><img src="/assets/images/testimonials/2.jpg" alt="Customer Testimonails"></a>
								</div>
								<div class="testi-content">
									<p>Natus voluptatum enim quod necessitatibus quis expedita harum provident eos obcaecati id culpa corporis molestias.</p>
									<div class="testi-meta">
										Collis Ta'eed
										<span>Envato Inc.</span>
									</div>
								</div>
							</div>
							<div class="slide">
								<div class="testi-image">
									<a href="#"><img src="/assets/images/testimonials/1.jpg" alt="Customer Testimonails"></a>
								</div>
								<div class="testi-content">
									<p>Incidunt deleniti blanditiis quas aperiam recusandae consequatur ullam quibusdam cum libero illo rerum!</p>
									<div class="testi-meta">
										John Doe
										<span>XYZ Inc.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div> -->
		</div>
	</section><!-- #content end -->
  </div>
</template>

<script>


export default {
	data() {
		return {
			
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>

<style scoped>
#page-title {
    padding-top:110px;
}

</style>